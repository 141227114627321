export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()

  const validUser = computed(() => {
    if (
      user.value &&
      user.value.user_metadata &&
      user.value.user_metadata.dashboard &&
      user.value.user_metadata.dashboard === 'quo-supa-admin'
    ) {
      return true
    }
    return false
  })

  // TODO: #43 Investigate, why navigateTo is not working.
  const router = useRouter()

  if (!user.value) {
    if (to.path) {
      return router.push(
        '/auth/sign-in?redirect=' + encodeURIComponent(to.fullPath)
      )
    }
    return router.push('/auth/sign-in')
  } else if (user.value && !validUser.value) {
    return router.push('/')
  }
})
